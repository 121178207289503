import {
    FETCH_ALL_CLIENTS,
    FETCH_ALL_PRODUCTS,
    ADD_INVOICE,
    FETCH_ALL_INVOICE,
    DELETE_INVOICE,
    UPDATE_INVOICE,
    GET_DASHBOARD,
    LOGOUT_ADMIN,
    GET_TRANSACTION_DETAILS,
} from "./ActionTypes";
import Axios from "axios"



export const FetchAllClients = () => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.get("/api/ClientUser/get");
        dispatch({ type: FETCH_ALL_CLIENTS, payload: res.data.Result });
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        console.log(error);
    }
}

export const UpdateClients = () => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.get("/api/ClientUser/update");
        dispatch({ type: FETCH_ALL_CLIENTS, payload: res.data.Result });
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        console.log();
    }
}

export const FetchAllProducts = () => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.get("/api/Product/get");
        dispatch({ type: FETCH_ALL_PRODUCTS, payload: res.data.Result });
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        console.log(error);
    }
}

export const UpdateProducts = () => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.get("/api/Product/update");
        dispatch({ type: FETCH_ALL_PRODUCTS, payload: res.data.Result });
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        console.log(error);
    }
}

export const AddInvoice = (invoiceDetails, history) => async(dispatch) => {
    try{
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.post("/api/Invoice/add", invoiceDetails);
        dispatch({type:ADD_INVOICE, payload: res.data.Result});
        history.push("/dashboard/invoice");
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        console.log(error);
    }
}

export const FetchAllInvoice = () => async(dispatch) =>{
    try{
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.get("/api/Invoice/get");
        dispatch({type:FETCH_ALL_INVOICE, payload: res.data.Result})
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        console.log(error);
    }
}

export const DeleteInvoice = (invoiceDetails) => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.post("/api/Invoice/delete", invoiceDetails);
        dispatch({ type: DELETE_INVOICE, payload: res.data.Result });
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        console.log(error);
    }
}

export const UpdateInvoice = (invoiceDetails, history, pagingInfo) => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.post("/api/Invoice/update", invoiceDetails);
        dispatch({ type: UPDATE_INVOICE, payload: res.data.Result });
        history.push({
            pathname: "/dashboard/invoice",
            state: {
                pagingInfo,
                status: { isInvoiceUpdated: true},
            },
        });
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        console.log(error);
    }
}

export const getDashboardInfo = () => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.get("/api/Dashboard/get")
        dispatch({ type: GET_DASHBOARD, payload: res.data.Result });
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        console.log(error);
    }
}

export const getTransactionDetails = (invoice) => async (dispatch) => {
    try {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('Auth_Token')}`;
        const res = await Axios.post("/api/Invoice/transaction/get", invoice);
        dispatch({ type: GET_TRANSACTION_DETAILS, payload: res.data.Result });
    } catch (error) {
        if (error.response.data.StatusCode == 401) {
            localStorage.removeItem('Auth_Token')
            dispatch({ type: LOGOUT_ADMIN });
        }
        console.log(error);
    }
    
}