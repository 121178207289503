import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import GlobalStateProvider from './store/GlobalStateProvider';
import AdminLogin from "./Routes/AdminLogin/AdminLogin";
import AdminDashboard from "./Routes/AdminDashboard/AdminDashboard";
import AdminProtectedRoutes from "./components/AdminProtectedRoutes/AdminProtectedRoutes";
import UserInvoice from "./Routes/UserInvoice/UserInvoice";
import UserPaymentFailed from "./Routes/UserPaymentFailed/UserPaymentFailed";
import UserPaymentSuccess from "./Routes/UserPaymentSuccess/UserPaymentSuccess";

export default function App() {
    //class App extends Component { 

    const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout/DefaultLayout'));
    //const Login = React.lazy(() => import('./Views/Pages/Login/Login')); 
    const Register = React.lazy(() => import('./Views/Pages/Register/Register'));
    const Home = React.lazy(() => import('./Views/Home/index'));


    return (

        <GlobalStateProvider>
            <BrowserRouter>
                <React.Suspense fallback={<LinearProgress className="LinearProgress"></LinearProgress>}>
                    <Switch>
                        <Route path="/payment/success" component={UserPaymentSuccess} />
                        <Route path="/payment/failed" component={UserPaymentFailed} />
                        <Route path="/user/:invoiceId" component={UserInvoice} />
                        <AdminProtectedRoutes path="/dashboard" component={AdminDashboard} />
                        <Route path="/" component={AdminLogin} />
                        {/*<Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} /> */} */}
              </Switch>
                </React.Suspense>
            </BrowserRouter>
        </GlobalStateProvider>

    );
}

// export default App;
