import React from "react";
import { connect } from "react-redux";

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Moment from 'moment';
import SearchBar from "material-ui-search-bar";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { FetchAllInvoice, DeleteInvoice } from "../../Redux/Actions/AdminDashboardActions"

class AdminInvoiceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invoiceSorted: false,
            clientSorted: false,
            productSorted: false,
            amountSorted: false,
            dateSorted: false,
            statusSorted: false,
            direction: 'desc',
            orderBy: 'invoiceNumber',
            sortType: 'default',
            currentPage: 0,
            pageSize: 10,
            startIndex: 0,
            endIndex: 1,
            totalItems: 0,
            searchKeyword: '',
            applyFilter: false,
            toggleFlag: false,
            isInvoiceUpdated: false,
        }
        this.GetTableBody = this.GetTableBody.bind(this);
        this.handleDetail = this.handleDetail.bind(this);
        this.handleNewInvoice = this.handleNewInvoice.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleSearchKeywordChange = this.handleSearchKeywordChange.bind(this);
        this.handleCancelSearch = this.handleCancelSearch.bind(this);
        this.handleSearchRequest = this.handleSearchRequest.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.setInvoiceSortingParameters = this.setInvoiceSortingParameters.bind(this);
        this.sortInvoices = this.sortInvoices.bind(this);
        this.setStartEndIndex = this.setStartEndIndex.bind(this);
        this.numberFormat = this.numberFormat.bind(this);
        this.restorePagingInfo = this.restorePagingInfo.bind(this);
    }

    componentDidMount() {
        const state = this.props.location.state;
        if (state === undefined) {
            this.props.FetchAllInvoice().then(res => this.setStartEndIndex());
        } else {
            this.props.FetchAllInvoice().then(this.restorePagingInfo());
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.applyFilter !== this.state.applyFilter && prevState.toggleFlag === this.state.toggleFlag) {
            this.setStartEndIndex();
        }
        if (prevState.searchKeyword !== this.state.searchKeyword && prevState.toggleFlag === this.state.toggleFlag) {
            this.setStartEndIndex();
        }
    }

    handleDetail(invoice) {
        const { currentPage, startIndex, endIndex, totalItems, applyFilter, searchKeyword, } = this.state;
        let pagingInfo = {
            currentPage,
            startIndex,
            endIndex,
            totalItems,
            applyFilter,
            searchKeyword,
        }
        this.props.history.push({
            pathname: "/dashboard/invoice/info",
            state: { invoice, pagingInfo},
        });
    }

    handleNewInvoice() {
        this.props.history.push({
            pathname: "/dashboard/invoice/add"
        })
    }

    handleDelete(invoice) {
        const invoiceDetail = {
            InvoiceId: invoice.invoiceId,
        }
        this.props.DeleteInvoice(invoiceDetail);
    }

    handleEdit(invoice) {
        const { currentPage, startIndex, endIndex, totalItems, applyFilter, searchKeyword, } = this.state;
        let pagingInfo = {
            currentPage,
            startIndex,
            endIndex,
            totalItems,
            applyFilter,
            searchKeyword,
        }
        this.props.history.push(
            {
                pathname: "/dashboard/invoice/edit",
                state: { invoice, pagingInfo },
            }
        );
    }


    handleSearchKeywordChange(newKeyword) {
        let applyFilter = true;
        if (newKeyword === "") {
            applyFilter = false;
        }
        this.setState(prevState => {
            return {
                ...prevState,
                searchKeyword: newKeyword,
                applyFilter,
            }
        });
    }

    handleCancelSearch() {
        this.setState(prevState => {
            return {
                ...prevState,
                searchKeyword: '',
                applyFilter: false,
            }
        });
    }

    handleSearchRequest() {
        this.setState(prevState => {
            return {
                ...prevState,
                applyFilter: true,
            }
        });
    }

    handleKeyPress(event) {
        if (event.key == "Escape") {
            if (event.target.name == "searchBar") {
                this.handleCancelSearch();
            }

        }
    }


    numberFormat(value) {
        return new Intl.NumberFormat("GBP", {
            style: "currency",
            currency: "GBP",
        }).format(value);
    }

    restorePagingInfo() {
        const { currentPage, startIndex, endIndex, totalItems, applyFilter, searchKeyword } = this.props.location.state.pagingInfo;
        let isInvoiceUpdated = false;
        if (this.props.location.state.status !== undefined) {
            isInvoiceUpdated = this.props.location.state.status.isInvoiceUpdated;
        }
        this.setState(prevState => {
            return {
                ...prevState,
                currentPage,
                startIndex,
                endIndex,
                totalItems,
                applyFilter,
                searchKeyword,
                isInvoiceUpdated,
                toggleFlag: !this.state.toggleFlag,
            }
        });
    }

    setInvoiceSortingParameters(feild) {
        const sortType = this.state.sortType;
        let type = "";
        let direction = '';
        let invoiceSorted = false;
        let clientSorted = false;
        let productSorted = false;
        let amountSorted = false;
        let dateSorted = false;
        let statusSorted = false;
        if (sortType === "default") {
            type = "ascending";
            direction = 'asc';
        } else if (sortType === "ascending") {
            type = "descending";
            direction = 'desc';
        } else if (sortType === "descending") {
            type = "default";
        }

        if (type === "ascending" || type === "descending") {
            if (feild === "customerName") {
                clientSorted = true;
            } else if (feild === "productName") {
                productSorted = true;
            } else if (feild === "invoiceNumber") {
                invoiceSorted = true;
            } else if (feild === "invoiceAmount") {
                amountSorted = true;
            } else if (feild === "invoiceDate") {
                dateSorted = true;
            } else if (feild === "paymentStatus") {
                statusSorted = true;
            }

        }


        this.setState(prevState => {
            return {
                ...prevState,
                orderBy: feild,
                sortType: type,
                direction,
                clientSorted,
                invoiceSorted,
                productSorted,
                amountSorted,
                dateSorted,
                statusSorted,
            }
        });
    }

    applyFilter() {
        let invoices = this.props.invoices;
        const searchKeyword = this.state.searchKeyword;
        const result = invoices.filter(invoice => {
            if (invoice["invoiceNumber"] !== null) {
                let invoiceNumber = "INV_OC_" + invoice["invoiceNumber"].toString();
                if (invoiceNumber.toLowerCase().includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            }
            if (invoice["customerName"] !== null) {
                if (invoice["customerName"].toLowerCase().includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            }
            if (invoice["productName"] !== null) {
                if (invoice["productName"].toLowerCase().includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            }
            if (invoice["invoiceAmount"] !== null) {
                let invoiceAmount = this.numberFormat(invoice["invoiceAmount"]);
                if (invoiceAmount.toString().replace(',','').includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            }
            if (invoice["invoiceDate"] !== null) {
                let date = Moment(invoice["invoiceDate"]).format('DD-MM-YYYY');
                if (date.includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            }
            if (invoice["paymentStatus"] !== null) {
                if (invoice["paymentStatus"].toLowerCase().includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            }
            else {
                return false;
            }
        });
        return result;
    }

    sortInvoices(a, b) {
        const sortType = this.state.sortType;
        const orderBy = this.state.orderBy;
        if (sortType === "default") {
            return a;
        } else if (sortType === "ascending") {
            if (a[orderBy] > b[orderBy]) {
                return 1;
            } else if (a[orderBy] <= b[orderBy]) {
                return -1;
            }

        } else if (sortType === "descending") {
            if (a[orderBy] >= b[orderBy]) {
                return -1;
            } else if (a[orderBy] < b[orderBy]) {
                return 1;
            }
        }
    }

    setStartEndIndex(event, newPage = 0) {
        const { pageSize, applyFilter } = this.state;
        let totalItems;
        if (applyFilter) {
            totalItems = this.applyFilter();
            totalItems = totalItems.length;
        } else {
            totalItems = this.props.invoices.length;
        }
        const currentPage = newPage;
        let startIndex = (currentPage) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
        this.setState({
            ...this.state,
            startIndex: startIndex,
            endIndex: endIndex,
            currentPage: newPage,
            totalItems
        });
    }

    GetTableBody() {
        const { applyFilter } = this.state;
        let invoices;
        if (applyFilter) {
            invoices = this.applyFilter();
        } else {
            invoices = this.props.invoices;
        }
        const markup = [...invoices].sort(this.sortInvoices).map((invoice) => (
            <TableRow key={invoice.invoiceId}>
                <TableCell component="th" scope="row">
                    INV_OC_{invoice.invoiceNumber}
                </TableCell>
                <TableCell align="left">{invoice.customerName}</TableCell>
                <TableCell align="left">{invoice.productName}</TableCell>
                <TableCell align="left">{this.numberFormat(invoice.invoiceAmount)}</TableCell>
                <TableCell align="left">{Moment(invoice.invoiceDate).format('DD-MM-YYYY')}</TableCell>
                <TableCell align="left">{invoice.paymentStatus}</TableCell>
                <TableCell align="left">
                    {
                        invoice.paymentStatus == "Deleted" || invoice.paymentStatus == "Paid" ?
                            invoice.paymentStatus == "Paid" ? <Button variant="contained" color="primary" onClick={() => this.handleDetail(invoice)}>Detail</Button> : "" :
                            <Button variant="contained" color="primary" onClick={() => this.handleEdit(invoice)}>Edit</Button>
                    }
                </TableCell>
                <TableCell align="left">
                    {
                        invoice.paymentStatus == "Deleted" || invoice.paymentStatus == "Paid" ?
                            "" :
                            < Button variant="contained" className="btn-danger" onClick={() => this.handleDelete(invoice)}>Delete</Button>
                    }
                </TableCell>

            </TableRow>
        ));
        return markup.slice(this.state.startIndex, this.state.endIndex + 1);
    }
    render() {
        const tableBodyMarkup = this.GetTableBody();
        return (

            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Typography component="h3" />
                    <div className={"alert alert-success p-2 " + (this.state.isInvoiceUpdated ? "d-block" : "d-none")} role="alert">
                        Invoice Updated Successfully
                    </div>
                    <div className="mb-15 text-right">
                        <Button variant="outlined" color="primary" size="small" startIcon={<AddIcon />} onClick={this.handleNewInvoice} > New Invoice</Button>
                    </div>
                    <SearchBar
                        value={this.state.searchKeyword}
                        cancelOnEscape={true}
                        onChange={(newKeyword) => this.handleSearchKeywordChange(newKeyword)}
                        onRequestSearch={() => this.handleSearchRequest()}
                        onCancelSearch={() => this.handleCancelSearch()}
                        inputRef={this.searchBarRef}
                        name="searchBar"
                        onKeyDown={this.handleKeyPress}
                    />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.invoiceSorted}
                                            direction={this.state.direction}
                                            onClick={() => this.setInvoiceSortingParameters("invoiceNumber")}
                                        >
                                            Invoice Number
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.clientSorted}
                                            direction={this.state.direction}
                                            onClick={() => this.setInvoiceSortingParameters("customerName")}
                                        >
                                            Client Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.productSorted}
                                            direction={this.state.direction}
                                            onClick={() => this.setInvoiceSortingParameters("productName")}
                                        >
                                            Product Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.amountSorted}
                                            direction={this.state.direction}
                                            onClick={() => this.setInvoiceSortingParameters("invoiceAmount")}
                                        >
                                            Invoice Amount
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.dateSorted}
                                            direction={this.state.direction}
                                            onClick={() => this.setInvoiceSortingParameters("invoiceDate")}
                                        >
                                            Date
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.statusSorted}
                                            direction={this.state.direction}
                                            onClick={() => this.setInvoiceSortingParameters("paymentStatus")}
                                        >
                                            Status
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableBodyMarkup}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        count={this.state.totalItems}
                        rowsPerPage={this.state.pageSize}
                        page={this.state.currentPage}
                        onChangePage={this.setStartEndIndex}
                    />
                </Container>

            </React.Fragment>
        )

    }

}

function mapStateToProps(store) {
    return {
        invoices: store.Admin.invoices,
    }
}

const mapActionsToProps = {
    FetchAllInvoice,
    DeleteInvoice,
}

export default connect(mapStateToProps, mapActionsToProps)(AdminInvoiceList)