import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";
import Menu from "../../containers/DefaultLayout/Menu";
import RightBar from "../../containers/DefaultLayout/rightBar";
import Header from "../../containers/DefaultLayout/Header";
import Footer from "../../containers/DefaultLayout/Footer";
import AdminClientsDetail from "../AdminClientsDetail/AdminClientsDetail";
import AdminClientDetail from "../AdminClientDetail/AdminClientDetail";
import AdminInvoice from "../AdminInvoice/AdminInvoice";
import AdminInvoiceEdit from "../AdminInvoiceEdit/AdminInvoiceEdit";
import AdminProductsDetail from "../AdminProductsDetail/AdminProductsDetail";
import AdminProductDetail from "../AdminProductDetail/AdminProductDetail";
import AdminInvoiceList from "../AdminInvoiceList/AdminInvoiceList";
import AdminInvoiceDetail from "../AdminInvoiceDetail/AdminInvoiceDetail";
import AdminInfo from "../AdminInfo/AdminInfo";
import Container from '@material-ui/core/Container';
import { FetchAllClients, UpdateClients, FetchAllProducts, getDashboardInfo } from "../../Redux/Actions/AdminDashboardActions";

class AdminDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleState: true
        }
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
    }

    componentDidMount() {
        this.props.FetchAllClients();
        this.props.FetchAllProducts();
        this.props.getDashboardInfo();
    }

    handleToggleMenu() {
        this.setSate({...this.state, toggleState: !this.state.toggleState})
    }

    render() {
        const { match } = this.props;
        return (
            <React.Fragment>
                <Header />
                <div className="row m-0">
                <div className="col-lg-3 col-xl-2 p-0"><Menu toggleProp={this.state.toggleState} /></div>
                <div className="col-lg-9 col-md-12 col-sm-12 col-xl-10 pr-0 main-wraper">
                <Container>
                    < Switch >
                    <Route path={`${match.url}/invoice/info`} component={AdminInvoiceDetail} />
                    <Route path={`${match.url}/invoice/edit`} component={AdminInvoiceEdit} />
                    <Route path={`${match.url}/invoice/add`} component={AdminInvoice} />
                    <Route path={`${match.url}/invoice`} component={AdminInvoiceList} />
                    <Route path={`${match.url}/info`} component={AdminInfo} />
                    <Route path={`${match.url}/client`} component={AdminClientDetail} />
                    <Route path={`${match.url}/clients`} component={AdminClientsDetail} />
                    <Route path={`${match.url}/product`} component={AdminProductDetail} />
                    <Route path={`${match.url}/products`} component={AdminProductsDetail} />
                    </Switch >
                </Container>
                </div>
                </div>
                <Footer />
                </React.Fragment>
            )
            
    }
}
function mapStateToProps(store) {
    return {
        Clients: store.Admin.clients
    }
}

const mapActionsToProps = {
    FetchAllClients,
    UpdateClients,
    FetchAllProducts,
    getDashboardInfo,
}

export default connect(mapStateToProps, mapActionsToProps)(AdminDashboard);