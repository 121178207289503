import React from 'react';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {
    Card, CardContent, Divider,
    Dialog, DialogContent, DialogTitle, Collapse, TableContainer, Table, TableCell, TableHead, TableRow,
    TableBody
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './CreateInvoice.css'
import SaveIcon from '@material-ui/icons/Save';

import { UpdateInvoice } from "../../Redux/Actions/AdminDashboardActions";



class AdminInvoiceEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            invoiceId: '',
            invoice: '',
            selectedDate: new Date(),
            isClientSelected: false,
            client: { userId: '', name: '' },
            product: { productId: '', name: '', description: '' },
            isProductSelected: false,
            amount: '',
            notes: '',
            errors: { client: '', product: '', description: '', amount: '' },
            showClientError: false,
            showProductError: false,
            showAmountError: false,
            disableUpdate: false,

        }
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getListOfClients = this.getListOfClients.bind(this);
        this.getListOfProducts = this.getListOfProducts.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.SetClient = this.SetClient.bind(this);
        this.SetProduct = this.SetProduct.bind(this);
        this.validation = this.validation.bind(this);
        this.handleInvoiceNumber = this.handleInvoiceNumber.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleValueLoading = this.handleValueLoading.bind(this);
    }

    componentDidMount() {
        this.handleValueLoading();
    }

    handleDateChange(date) {
        this.setState({ ...this.state, selectedDate: date })
    }

    handleChange(event) {
        this.setState({ ...this.state, [event.target.name]: event.target.value })
    }

    handleInvoiceNumber() {
        const invoices = this.props.invoices;
        if (invoices.length === 0) {
            this.setState({ ...this.state, invoice: 1 });
        } else if (invoices.length >= 1) {
            let currentInoviceNumber = invoices[invoices.length - 1].invoiceNumber;
            this.setState({ ...this.state, invoice: currentInoviceNumber + 1 });
        }

    }

    handleBack() {
        const pagingInfo = this.props.location.state.pagingInfo;

        this.props.history.push({
            pathname: "/dashboard/invoice",
            state: { pagingInfo },
        });
    }

    handleValueLoading() {
        const invoice = this.props.location.state.invoice;
        this.setState(prevState => {
            return {
                ...prevState,
                invoiceId: invoice.invoiceId,
                invoice: invoice.invoiceNumber,
                selectedDate: invoice.invoiceDate,
                isClientSelected: true,
                client: { userId: invoice.customerId, name: invoice.customerName},
                product: { productId: invoice.productId, name: invoice.productName, description: invoice.productDescription },
                isProductSelected: true,
                amount: invoice.invoiceAmount.toString(),
                notes: invoice.notes,
            }
        })
    }

    getListOfClients() {
        const clients = this.props.clients;
        const markup = clients.map(client => (<MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>));
        return markup;
    }

    getListOfProducts() {
        const products = this.props.products;
        const markup = products.map(client => (<MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>));
        return markup;
    }

    validation() {
        let errors = {};
        let valid = false;
        let showClientError = false;
        let showProductError = false;
        let showAmountError = false;

        if (this.state.amount == '') {
            errors.amount = "Please enter a amount";
        } else if (this.state.amount < 0) {
            errors.amount = "Please enter a positive amount";
        } else if (this.state.amount.toString().search(/\./) != -1) {
            if (this.state.amount.toString().split('.')[1].length > 2) {
                errors.amount = "Please enter a amount with decimal points upto 2 only"
            }
        }
        if (this.state.amount.toString().split('.')[0].length > 7) {
            errors.amount = "Please enter a amount less then 7 digit excluding decimal points";
        }

        if (this.state.client.userId == '') {
            errors.client = "Please select a client";
        }
        if (this.state.product.productId == '') {
            errors.product = 'Please select a product';
        }
        let errorTypes = Object.keys(errors);
        if (errorTypes.length == 0) {
            valid = true;
        } else {
            for (let i = 0; i < errorTypes.length; i++) {
                if (errorTypes[i] == "amount") {
                    showAmountError = true;
                } else if (errorTypes[i] == "client") {
                    showClientError = true;
                } else if (errorTypes[i] == "product") {
                    showProductError = true;
                }
            }
        }

        return { errors, showAmountError, showClientError, showProductError, valid };
    }

    handleSubmit() {
        const { errors, showAmountError, showClientError, showProductError, valid } = this.validation();
        const pagingInfo = this.props.location.state.pagingInfo;
        if (valid) {

            const invoiceDetails = {
                InvoiceId: this.state.invoiceId,
                InvoiceNumber: this.state.invoice,
                InvoiceDate: this.state.selectedDate,
                CustomerId: this.state.client.userId,
                ProductId: this.state.product.productId,
                InvoiceAmount: this.state.amount,
                Notes: this.state.notes
            }
            this.setState(prevState => ({ ...prevState, disableUpdate: true }));
            this.props.UpdateInvoice(invoiceDetails, this.props.history, pagingInfo);
        } else {
            this.setState(prevState => ({ ...prevState, errors: errors, showAmountError, showClientError, showProductError }));
        }
    }

    SetClient = (e, value) => {
        if (value === null) {
            this.setState({ ...this.state, isClientSelected: false, client: { userId: '', name: '' } });
        }
        else {
            this.setState({ ...this.state, isClientSelected: true, client: value });
        }
    }


    SetProduct = (e, value) => {
        if (value === null) {
            this.setState({ ...this.state, isProductSelected: false, product: { productId: '', name: '', description: '' } });
        }
        else {
            this.setState({ ...this.state, isProductSelected: true, product: value });
        }

    }


    render() {
        const clientsMarkup = this.getListOfClients();
        const productsMarkup = this.getListOfProducts();
        return (
            <div>


                <div>
                    <Grid container component="main" spacing={0} >
                        <Grid item xs={12} sm={12} md={12} xl={12} >
                            <div >
                                <Card square variant='outlined'>
                                    <CardContent spacing={0}>
                                        <Grid component="main" container spacing={1}>
                                            <div className="justify-content-beetween w-100 d-md-flex">
                                                <Grid item xs={12} sm={6} md={3} xl={3} >
                                                    <Grid container justify='flex-end' component='main'>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} size='small'>
                                                            <TextField type="number" disabled fullWidth label='Invoice No' margin='dense' size='medium' variant="outlined" name="invoice" value={this.state.invoice} ></TextField>
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} sm={3} md={2} xl={2} >

                                                    <Grid container justify='flex-end' component='main'>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} size='small'>

                                                            <Grid container justify="space-around">
                                                                <KeyboardDatePicker size='small'

                                                                    margin="dense"
                                                                    id="InvoiceDate"
                                                                    label="Invoice Date"
                                                                    format="dd/MM/yyyy"
                                                                    variant="outlined"
                                                                    disableToolbar
                                                                    value={this.state.selectedDate}
                                                                    onChange={this.handleDateChange}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </MuiPickersUtilsProvider>

                                                        {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                                                        {/*<Grid container justify="space-around"> */}
                                                        {/*    <KeyboardDatePicker size='small'*/}
                                                        {/*    variant='outlined'*/}
                                                        {/*    fullWidth*/}
                                                        {/*    className={classes.inputStyle}*/}
                                                        {/*    margin="dense"*/}
                                                        {/*    id="DueDate"*/}
                                                        {/*    label="Due Date"*/}
                                                        {/*    format="MM/dd/yyyy"*/}
                                                        {/*    value={selectedDate}*/}
                                                        {/*    onChange={handleDateChange}*/}
                                                        {/*    KeyboardButtonProps={{*/}
                                                        {/*        'aria-label': 'change date',*/}
                                                        {/*    }}*/}
                                                        {/*    /> */}
                                                        {/*</Grid>*/}
                                                        {/*</MuiPickersUtilsProvider>*/}

                                                    </Grid>

                                                </Grid>

                                            </div>

                                            <Grid item xs={12} sm={12} md={12} xl={12}  >
                                                <Divider></Divider>
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                                <Typography variant='h6'><b>Invoice To</b></Typography>
                                            </Grid>



                                            <Grid item xs={12} sm={6} md={4} xl={4}>
                                                <Autocomplete
                                                    id="ClientSelector"
                                                    name="ClientSelector"
                                                    options={this.props.clients}
                                                    getOptionLabel={(clients) => clients.name}
                                                    size='small'
                                                    onChange={(event, newValue) => this.SetClient(event, newValue)}
                                                    inputValue={this.state.client.name}
                                                    value={this.state.client.name}
                                                    renderInput={(params) => <TextField margin='dense' {...params} label="Select Client" variant="outlined" />} />
                                                <FormHelperText id="ClientSelector" className="text-danger">
                                                    {this.state.errors.client}
                                                </FormHelperText>
                                            </Grid>


                                            <Grid item xs={12} sm={6} md={4} xl={4}>
                                                <Autocomplete
                                                    id="ProductSelector"
                                                    name="ProductSelector"
                                                    options={this.props.products}
                                                    getOptionLabel={(products) => products.name}
                                                    size='small'
                                                    onChange={(event, newValue) => this.SetProduct(event, newValue)}
                                                    //className={classes.inputStyle}
                                                    inputValue={this.state.product.name}
                                                    value={this.state.product.name}
                                                    renderInput={(params) => <TextField margin='dense' {...params} label="Select Product" variant="outlined" />} />
                                                <FormHelperText id="ProductSelector" className="text-danger">
                                                    {this.state.errors.product}
                                                </FormHelperText>
                                            </Grid>



                                            <Grid item xs={12} sm={6} md={4} xl={4}>
                                                <TextField type="number" fullWidth label='Amount' margin='dense' id="amount" name="amount" value={this.state.amount} size='small' variant="outlined" onChange={this.handleChange} ></TextField>
                                                <FormHelperText id="amount" className="text-danger">
                                                    {this.state.errors.amount}
                                                </FormHelperText>
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                                <Collapse in={this.state.isProductSelected}>
                                                    <Grid item xs={12} sm={12} md={12} xl={12}>
                                                        <TextField multiline rowsMax={10} fullWidth margin='dense' value={this.state.product.description} size='small' variant="outlined" ></TextField>
                                                    </Grid>
                                                </Collapse>
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={12} xl={12}>
                                                <TextField multiline rowsMax={10} fullWidth label='Notes' margin='dense' name="notes" onChange={this.handleChange} value={this.state.notes} size='small' variant="outlined" ></TextField>
                                                <div className="mt-3 w-100 text-right">
                                                    <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={() => this.handleSubmit()} disabled={this.state.disableUpdate}>Update</Button>
                                                    <Button variant="contained" className="btn btn-default ml-2" onClick={() => this.handleBack()}>Go Back</Button>
                                                </div>
                                            </Grid>





                                        </Grid>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                        {/*<Grid item xs={12} sm={12} md={3} xl={3}>*/}
                        {/*    <div className={classes.sideControl } >*/}
                        {/*        <div>*/}
                        {/*            rahul*/}
                        {/*            <TextField    fullWidth   size='small'  variant="outlined"    ></TextField> */}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                    </Grid>
                </div>
            </div>
        )


    }
}

function mapStateToProps(store) {
    return { clients: store.Admin.clients, products: store.Admin.products, invoices: store.Admin.invoices, }
}

const mapActionsToProps = {
    UpdateInvoice
}


export default connect(mapStateToProps, mapActionsToProps)(AdminInvoiceEdit)