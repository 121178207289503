import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Button, Grid, Avatar, Popover, Typography, Menu, MenuItem, ListItem, ListItemIcon, List, ListItemText } from '@material-ui/core';
import { PersonTwoTone, LockTwoTone } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';


const useStyles = makeStyles((theme) => ({
    root: { flexGrow: 1 },
    padding8: { padding: 8 },
    textOrigional: { textTransform: 'initial' },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: 12,
        marginLeft: 5,
        background: '#eef1ff',
        color: '#5c77ff'
    },
}));

export default function Footer(props) {



    return (
        <div className="Page-Footer">
        <Grid container>
     
                <Grid item xs={12} sm={12} md={12} xl={12} >
                    <img src="https://owner-collection.com/wp-content/themes/owner-collection/static/img/footer-logo.png" />
                    <p className="show-for-medium">2021 by <a href="https://theloyaltyco.app/" target="_blank">THELOYALTYCO</a>.</p>
                </Grid>

            </Grid>
        </div>

    );
}