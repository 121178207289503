import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'

class AdminProductDetail extends React.Component {
    constructor(props) {
        super(props);
        this.GetTableBody = this.GetTableBody.bind(this);
        this.handleGoBack = this.handleGoBack.bind(this);
    }

    handleGoBack() {
        const pagingInfo = this.props.location.state.pagingInfo;
        this.props.history.push({
            pathname: "/dashboard/products",
            state: { pagingInfo },
        });
    }

    GetTableBody() {
        const product = this.props.location.state.product;
        return (<TableBody className="detail-table">
            <TableRow>
                <TableCell scope="row">
                    Name
            </TableCell>
                <TableCell align="left">{product.name}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">{product.type}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">{product.description}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left"><Button variant="contained" color="primary" onClick={this.handleGoBack}>Go Back</Button></TableCell>
            </TableRow>
        </TableBody>
        )

    }
    render() {
        const tableBodyMarkup = this.GetTableBody();
        return (
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Typography component="h3" />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Product Detail</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            {tableBodyMarkup}
                        </Table>
                    </TableContainer>
                </Container>
            </React.Fragment>
        )
    }
}

export default AdminProductDetail