import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useHistory } from "react-router-dom";
import "./UserPayment.css";
import axios from "axios";

const UserPayment = (props) => {
    const [succeeded, setSucceeded] = useState(false);
    const [failedTransaction, setFailedTransaction] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');

    const stripe = useStripe();
    const elements = useElements();
    let history = useHistory();
    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
      
    }, []);

    const saveSuccessfulTransaction = async (intentId) => {
        const transactionDetails = {
            IntentId: intentId,
            InvoiceId: props.invoiceId,
        }

        try {
            await axios.post("/create-payment-intent/add", transactionDetails);
        } catch (error) {
            console.log(error);
        }
    }


    const handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();
        setProcessing(true);
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        // Use your card Element with other Stripe.js APIs
        const payload = await stripe.confirmCardPayment(props.clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)
            }
        });
        
        if (payload.error) {
            saveSuccessfulTransaction(payload.error.payment_intent.id);
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
            setFailedTransaction(true);
            history.push("/payment/failed");
        } else {
            saveSuccessfulTransaction(payload.paymentIntent.id);
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            history.push("/payment/success");
        }

    };


    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };



    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
            <button className="btn btn-dark d-block w-100 p-1 font-20 mt-3"
                disabled={processing || disabled || succeeded || failedTransaction}
                id="submit"
            >
                <span id="button-text">
                    {processing ? (
                        <div className="spinner" id="spinner"></div>
                    ) : (
                            "Pay now"
                        )}
                </span>
            </button>
            {/* Show any error that happens when processing the payment */}
            {error && (
                <div className="card-error mt-2 text-danger" role="alert">
                    {error}
                </div>
            )}
            {/* Show a success message upon completion */}
            <p className={succeeded ? "result-message  mt-2 text-success" : "result-message hidden"}>
                Payment succeeded
      </p>
        </form>
    );
};

export default UserPayment;