import React from 'react';
import { connect } from "react-redux";
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Moment from 'moment';

import { getTransactionDetails } from "../../Redux/Actions/AdminDashboardActions"

class AdminInvoiceDetail extends React.Component {
    constructor(props) {
        super(props);
        this.GetTableBody = this.GetTableBody.bind(this);
        this.handleGoBack = this.handleGoBack.bind(this);
        this.numberFormat = this.numberFormat.bind(this);
    }

    componentDidMount() {
        this.props.getTransactionDetails({ "InvoiceId": this.props.location.state.invoice.invoiceId });
    }

    handleGoBack() {
        const pagingInfo = this.props.location.state.pagingInfo;
        this.props.history.push({
            pathname: "/dashboard/invoice",
            state: { pagingInfo },
        });
    }

    numberFormat(value) {
        return new Intl.NumberFormat("GBP", {
            style: "currency",
            currency: "GBP",
        }).format(value);
    }

    GetTableBody() {
        const invoice = this.props.location.state.invoice;
        const transaction = this.props.transaction;
        return (<TableBody className="detail-table">  
            <TableRow>
                <TableCell scope="row">
                    Invoice Number
            </TableCell>
                <TableCell align="left">INV_OC_{invoice.invoiceNumber }</TableCell>
                <TableCell align="left"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left" rowSpan={4}>Customer Detail</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">{invoice.customerName}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">{invoice.customerEmail}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Phone</TableCell>
                <TableCell align="left">{invoice.customerPhone}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left" rowSpan={4}>Product Detail</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">{invoice.productName}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">{invoice.productType}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Description</TableCell>
                <TableCell align="left">{invoice.productDescription}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Invoice Amount</TableCell>
                <TableCell align="left">{this.numberFormat(invoice.invoiceAmount)}</TableCell>
                <TableCell align="left"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Transaction Id</TableCell>
                <TableCell align="left">{transaction.transactionId }</TableCell>
                <TableCell align="left"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Payment Status</TableCell>
                <TableCell align="left">{invoice.paymentStatus}</TableCell>
                <TableCell align="left"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left">Transaction Date</TableCell>
                <TableCell align="left">{ Moment(transaction.paymentDate).format('DD-MM-YYYY')}</TableCell>
                <TableCell align="left"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"><Button variant="contained" color="primary" onClick={ this.handleGoBack }>Go Back</Button></TableCell>
            </TableRow>
            </TableBody>
        )
        
    }
    render() {
        const tableBodyMarkup = this.GetTableBody();
        return (
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Typography component="h3" />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Invoice Detail</TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                                {tableBodyMarkup}
                        </Table>
                    </TableContainer>
                </Container>
            </React.Fragment>
            )
    }
}

function mapStateToProps(store) {
    return {
        transaction: store.Admin.transaction,
    }
}

const mapActionToProps = {
    getTransactionDetails,
}

export default connect(mapStateToProps, mapActionToProps)(AdminInvoiceDetail);