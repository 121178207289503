import React from 'react';
import "./UserInvoice.css";
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { connect } from 'react-redux';
import ClientPayment from "../../components/UserPayment/UserPayment";
import axios from "axios";

const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');
class UserInvoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            hasError: false,
            clientSecret: '',
            productName: '',
            productDescription: '',
            productPrice: '',
            paid: false,
        }
        this.getPaymentIntent = this.getPaymentIntent.bind(this);
        this.numberFormat = this.numberFormat.bind(this);
    }

    componentDidMount() {
        this.getPaymentIntent();
    }

    numberFormat(value) {
        return new Intl.NumberFormat("GBP", {
            style: "currency",
            currency: "GBP",
        }).format(value);
    }

    async getPaymentIntent() {
        const invoice = {
            InvoiceId: this.props.match.params.invoiceId,
        }
        try {
            const res = await axios.post("/create-payment-intent", invoice);
            this.setState(prevState => {
                return {
                    ...prevState,
                    productName: res.data.productName,
                    productDescription: res.data.productDescription,
                    productPrice: res.data.invoiceAmount,
                    clientSecret: res.data.clientSecret,
                }
            });
        } catch (error) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    productName: error.response.data.productName,
                    productDescription: error.response.data.productDescription,
                    productPrice: error.response.data.invoiceAmount,
                    error: error.response.data.error,
                    paid: error.response.data.paid,
                    hasError: true,
                }
            });
        }
    }


    render() {
        return (
            <React.Fragment>

                <div className="page-content container">
                    <div className="container px-0">
                        <div className="row mt-4">
                            <div className="col-12 col-lg-10 offset-lg-1 bg-box">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-center"> <img src="https://owner-collection.com/wp-content/themes/owner-collection/static/img/logo_v2.svg" alt="" className="logo" /> </div>
                                    </div>
                                </div>
                                <hr className="row brc-default-l1 mx-n1 mb-4" />
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="payment-details">
                                            <div className="payment-details-items mb-3">
                                                <h3 className="text-blue mb-1">Product</h3>
                                                <p className="m-0 text-grey">{this.state.productName}</p>
                                            </div>
                                            <div className="payment-details-items mb-3">
                                                <h3 className="text-blue mb-1">Description</h3>
                                                <p className="m-0 text-grey">{this.state.productDescription}</p>
                                            </div>
                                            <div className="payment-details-items mb-3">
                                                <h3 className="text-blue mb-1">Total Amount</h3>
                                                <p className="font-24 m-0">{this.numberFormat(this.state.productPrice)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        {
                                            this.state.hasError ?
                                                <div className={"alert alert-" + (this.state.paid ? "success" : "danger")} role="alert">
                                                    {this.state.error}
                                                </div> :
                                                <Elements stripe={stripePromise}>
                                                    <ClientPayment clientSecret={this.state.clientSecret} invoiceId={this.props.match.params.invoiceId} />
                                                </Elements>
                                        }
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <footer>
                    <img src="https://owner-collection.com/wp-content/themes/owner-collection/static/img/footer-logo.png" alt="" />
                    <p>2021 by <a href="#">THELOYALTYCO.</a></p>
                </footer>
            </React.Fragment >
        );
    }
}

export default connect(null, null)(UserInvoice);