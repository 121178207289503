import "./UserInvoice.css";
import React from 'react';
//import logo from "./Failure.png";
class UserPaymentFailed extends React.Component {
 

    render() {
        return (
            <>
                
                    <div className="page-content container">
                        <div className="container px-0">
                            <div className="row mt-4">
                                <div className="col-12 col-lg-10 offset-lg-1 bg-box">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="text-center"> <img src="https://owner-collection.com/wp-content/themes/owner-collection/static/img/logo_v2.svg" alt="" className="logo" /> </div>
                                        </div>
                                    </div>
                                    <hr className="row brc-default-l1 mx-n1 mb-4" />
                                    <div className="text-center">
                                        <img src="/Failure.png" width="250" />
                                        <h1>Payment Failed</h1>
                                    </div>

                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <footer>
                        <img src="https://owner-collection.com/wp-content/themes/owner-collection/static/img/footer-logo.png" alt="" />
                        <p>2021 by <a href="#">THELOYALTYCO.</a></p>
                    </footer>
               
            </>
        );
    }
}

export default UserPaymentFailed;