import {
    FETCH_ALL_CLIENTS,
    FETCH_ALL_PRODUCTS,
    ADD_INVOICE,
    FETCH_ALL_INVOICE,
    DELETE_INVOICE,
    UPDATE_INVOICE,
    GET_DASHBOARD,
    GET_TRANSACTION_DETAILS,
} from "../Actions/ActionTypes"

const InitialState = {
    clients: [],
    products: [],
    invoices: [],
    transaction: {
        paymentDate: '',
        transactionId: ''
    },
    dashboard: {
        numberOfClients: '',
        numberOfInvoices: '',
        numberOfPaidInvoices: '',
        numberOfUnpadiInvoices: '',
    },

};

export default function AdminReducer(state = InitialState, action) {

    switch (action.type) {
        case FETCH_ALL_CLIENTS:
            return { ...state, clients: [...action.payload] };
        case FETCH_ALL_PRODUCTS:
            return { ...state, products: [...action.payload] };
        case FETCH_ALL_INVOICE:
            return { ...state, invoices: [...action.payload] };
        case ADD_INVOICE:
            return { ...state, invoices: [...action.payload] };
        case DELETE_INVOICE:
            return { ...state, invoices: [...action.payload]};
        case UPDATE_INVOICE:
            return { ...state, invoices: [...action.payload]};
        case GET_DASHBOARD:
            return { ...state, dashboard: action.payload};
        case GET_TRANSACTION_DETAILS:
            return { ...state, transaction: action.payload};
        default:
            return state;
    }
}



