import React, { createRef} from "react";
import { connect } from "react-redux";

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import SearchBar from "material-ui-search-bar";

import AdminClientDetail from "../AdminClientDetail/AdminClientDetail"
import { FetchAllClients, UpdateClients, FetchAllProducts } from "../../Redux/Actions/AdminDashboardActions";

class AdminClientsDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isClientUpdated: false,
            clientSorted: false,
            direction: 'asc',
            orderBy: '',
            sortType: 'default',
            currentPage: 0,
            pageSize: 10,
            startIndex: 0,
            endIndex: 1,
            searchKeyword: '',
            applyFilter: false,
            totalItems: 0,
            toggleFlag: false,
        }
        this.searchBarRef = createRef();
        this.handleDetails = this.handleDetails.bind(this);
        this.handleUpdateClients = this.handleUpdateClients.bind(this);
        this.handleSearchKeywordChange = this.handleSearchKeywordChange.bind(this);
        this.handleCancelSearch = this.handleCancelSearch.bind(this);
        this.handleSearchRequest = this.handleSearchRequest.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.setClientsSortingParameters = this.setClientsSortingParameters.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.sortClients = this.sortClients.bind(this);
        this.setStartEndIndex = this.setStartEndIndex.bind(this);
        this.GetTableBody = this.GetTableBody.bind(this);
        this.restorePagingInfo = this.restorePagingInfo.bind(this);
    }

    componentDidMount() {
        const state = this.props.location.state;
        if (state === undefined) {
            this.props.FetchAllClients().then(res => this.setStartEndIndex());
        } else {
            this.props.FetchAllClients().then(this.restorePagingInfo());
        }
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.applyFilter !== this.state.applyFilter && prevState.toggleFlag === this.state.toggleFlag) {
            this.setStartEndIndex();
        }
        if (prevState.searchKeyword !== this.state.searchKeyword && prevState.toggleFlag === this.state.toggleFlag) {
            this.setStartEndIndex();
        }
    }

    handleDetails(client) {
        const { currentPage, startIndex, endIndex, totalItems, applyFilter, searchKeyword, } = this.state;
        let pagingInfo = {
            currentPage,
            startIndex,
            endIndex,
            totalItems,
            applyFilter,
            searchKeyword,
        }
        this.props.history.push({
            pathname: "/dashboard/client",
            state: { client, pagingInfo },
        });
    }

    handleUpdateClients() {
        this.props.UpdateClients();
        this.setState({ ...this.state, isClientUpdated: true });
    }

    handleSearchKeywordChange(newKeyword) {
        let applyFilter = true;
        if (newKeyword === "") {
            applyFilter = false;
        }
        this.setState(prevState => {
            return {
                ...prevState,
                searchKeyword: newKeyword,
                applyFilter,
            }
        });
    }

    handleCancelSearch() {
        this.setState(prevState => {
            return {
                ...prevState,
                searchKeyword: '',
                applyFilter: false,
            }
        });
    }

    handleSearchRequest() {
        this.setState(prevState => {
            return {
                ...prevState,
                applyFilter: true,
            }
        });
    }

    handleKeyPress(event) {
        if (event.key == "Escape") {
            if (event.target.name == "searchBar") {
                this.handleCancelSearch();
            } 
            
        }
    }

    restorePagingInfo() {
        const { currentPage, startIndex, endIndex, totalItems, applyFilter, searchKeyword } = this.props.location.state.pagingInfo;
        this.setState(prevState => {
            return {
                ...prevState,
                currentPage,
                startIndex,
                endIndex,
                totalItems,
                applyFilter,
                searchKeyword,
                toggleFlag: !this.state.toggleFlag,
            }
        });
    }

    setClientsSortingParameters(feild) {
        const sortType = this.state.sortType;
        let type = "";
        let direction = '';
        let clientSorted = false;

        if (sortType === "default") {
            type = "ascending";
            direction = 'asc';
        } else if (sortType === "ascending") {
            type = "descending";
            direction = 'desc';
        } else if (sortType === "descending") {
            type = "default";
        }

        if (type === "ascending" || type === "descending") {
            if (feild === "name") {
                clientSorted = true;
            }

        }
        this.setState(prevState => {
            return {
                ...prevState,
                orderBy: feild,
                sortType: type,
                direction,
                clientSorted,
            }
        });
    }

    applyFilter() {
        let clients = this.props.Clients;
        const searchKeyword = this.state.searchKeyword;
        const result = clients.filter(client => {
            if (client["name"] !== null) {
                if (client["name"].toLowerCase().includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            }
            if (client["email"] !== null) {
                if (client["email"].toLowerCase().includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            }
            if (client["phone"] !== null) {
                if (client["phone"].toLowerCase().includes(searchKeyword.toLowerCase())) {
                    return true;
                }
            } else {
                return false;
            }
        });
        return result;
    }

    sortClients(a, b) {
        const sortType = this.state.sortType;
        const orderBy = this.state.orderBy;
        if (sortType === "default") {
            return a;
        } else if (sortType === "ascending") {
            if (a[orderBy] > b[orderBy]) {
                return 1;
            } else if (a[orderBy] <= b[orderBy]) {
                return -1;
            }

        } else if (sortType === "descending") {
            if (a[orderBy] >= b[orderBy]) {
                return -1;
            } else if (a[orderBy] < b[orderBy]) {
                return 1;
            }
        }
    }

    setStartEndIndex(event, newPage = 0) {
        const { pageSize, applyFilter } = this.state;
        let totalItems;
        if (applyFilter) {
            totalItems = this.applyFilter();
            totalItems = totalItems.length;
        } else {
            totalItems = this.props.Clients.length;
        }
        const currentPage = newPage;
        let startIndex = (currentPage) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
        this.setState({
            ...this.state,
            startIndex: startIndex,
            endIndex: endIndex,
            currentPage: newPage,
            totalItems
        });
    }

    GetTableBody() {
        const { applyFilter } = this.state;
        let clients;
        if (applyFilter) {
            clients = this.applyFilter();
        } else {

        clients = this.props.Clients;
        }
        let markup = [...clients].sort(this.sortClients).map((row) => (
            <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.phone}</TableCell>
                <TableCell align="left"><Button variant="contained" color="primary" onClick={() => this.handleDetails(row)}>Detail</Button></TableCell>
            </TableRow>
        ));
        return markup.slice(this.state.startIndex, this.state.endIndex + 1);
    }
    render() {
        const tableBodyMarkup = this.GetTableBody();
        return (

            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <div className={"alert alert-success p-2 " + (this.state.isClientUpdated?"d-block":"d-none") } role="alert">
                        Clients Updated Successfully
                    </div>
                    <Typography component="h3" />
                    <div className="mb-15 text-right">
                        <Button variant="outlined" color="primary" size="small" startIcon={<SaveIcon />} onClick={this.handleUpdateClients} > Update clients</Button>
                    </div>
                    <SearchBar
                        value={this.state.searchKeyword}
                        cancelOnEscape={true}
                        onChange={(newKeyword) => this.handleSearchKeywordChange(newKeyword)}
                        onRequestSearch={() => this.handleSearchRequest()}
                        onCancelSearch={() => this.handleCancelSearch()}
                        inputRef={this.searchBarRef}
                        name="searchBar"
                        onKeyDown={this.handleKeyPress}
                    />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        <TableSortLabel
                                            active={this.state.clientSorted}
                                            direction={this.state.direction}
                                            onClick={() => this.setClientsSortingParameters("name")}
                                        >
                                        Client Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="left">Client Email</TableCell>
                                    <TableCell align="left">Client Phone</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableBodyMarkup}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        count={this.state.totalItems}
                        rowsPerPage={this.state.pageSize}
                        page={this.state.currentPage}
                        onChangePage={this.setStartEndIndex}
                    />
                </Container>

            </React.Fragment>
        )

    }

}

function mapStateToProps(store) {
    return {
        Clients: store.Admin.clients
    }
}

const mapActionsToProps = {
    FetchAllClients,
    UpdateClients,
    FetchAllProducts
}

export default connect(mapStateToProps, mapActionsToProps)(AdminClientsDetails)