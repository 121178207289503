export const LOGIN_ADMIN = "LOGIN_ADMIN";
export const LOGOUT_ADMIN = "LOGOUT_ADMIN";
export const ADMIN_LOGIN_ERROR = "ADMIN_LOGIN_ERROR";
export const FETCH_ALL_CLIENTS = "FETCH_ALL_CLIENTS";
export const FETCH_ALL_PRODUCTS = "FETCH_ALL_PRODUCTS";
export const ADD_INVOICE = "ADD_INVOICE";
export const FETCH_ALL_INVOICE = 'FETCH_ALL_INVOICE';
export const DELETE_INVOICE = 'DELETE_INVOICE';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_TRANSACTION_DETAILS = 'GET_TRANSACTION_DETAILS';