import React from 'react';
import { connect } from "react-redux";
import { getDashboardInfo } from "../../Redux/Actions/AdminDashboardActions";

class AdminInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleState: true
        }
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
    }

    componentDidMount() {
        this.props.getDashboardInfo();
    }

    handleToggleMenu() {
        this.setSate({ ...this.state, toggleState: !this.state.toggleState })
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-14 text-dark mb-1">
                                            Clients</div>
                                        <div className="h5 mb-0 font-weight-bold text-primary">{this.props.dashboardInfo.numberOfClients}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-users fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-info shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-14 text-dark mb-1">
                                            Invoices</div>
                                        <div className="h5 mb-0 font-weight-bold text-info">{this.props.dashboardInfo.numberOfInvoices}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-file-invoice fa-2x text-gray-300"></i>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-14 text-dark mb-1">
                                            Paid Invoices</div>
                                        <div className="h5 mb-0 font-weight-bold text-success">{this.props.dashboardInfo.numberOfPaidInvoices }</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-check-double fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-danger shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-14 text-dark mb-1">
                                            Unpaid Invoices</div>
                                        <div className="h5 mb-0 font-weight-bold text-danger">{this.props.dashboardInfo.numberOfUnpadiInvoices }</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-times fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        )

    }
}

function mapStateToProps(store) {
    return {
        dashboardInfo : store.Admin.dashboard,
    }
}

const mapActionToProps = {
    getDashboardInfo,
}

export default connect(mapStateToProps, mapActionToProps)(AdminInfo);